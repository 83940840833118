

















type AccumulatorChartRecord = Pick<InviteLinkStatRecord, 'time' | 'enter_unique_first_users' | 'leave_unique_users' | 'kick_unique_users'>

import { InviteLinkStatRecord, InviteLinkWithStat } from "@/includes/logic/InviteLinks/types";
import { fromToTicks } from "piramis-base-components/src/shared/utils/fromToTicks";

import { ApexChartLocales, ApexChartSeries } from "piramis-base-components/src/shared/types/ApexChartSeries.types";
import { seriesByMetricInList } from "piramis-base-components/src/shared/utils/seriesByMetricInList";
import EmptyData from "piramis-base-components/src/components/EmptyData/EmptyData.vue";

import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { ApexOptions } from "apexcharts";
import VueApexCharts from "vue-apexcharts";

@Component({
  components: {
    VueApexCharts,
    EmptyData
  }
})
export default class AccumulatorChart extends Vue {
  @Prop() links!: Array<InviteLinkWithStat>

  @Prop() from !: string

  @Prop() to !: string

  @Watch('links')
  onchange() {
    this.calcChartSeries()
  }

  chartLoading = false

  series: ApexChartSeries | null = null

  options: ApexOptions | null = null

  get ticks() {
    return fromToTicks(this.from, this.to, {
      format: 'YYYY-MM-DD 00:00:00'
    })
  }

  calcChartSeries() {
    this.chartLoading = true

    const accStats = this.links
      .map(l => l.stat)
      .flat()
      .reduce((acc: Array<AccumulatorChartRecord>, value) => {
        if (value) {
          const record = acc.find(s => s.time === value.time)

          if (record) {
            record.enter_unique_first_users += value.enter_unique_first_users
            record.leave_unique_users += value.leave_unique_users
            record.kick_unique_users += value.kick_unique_users
          } else {
            acc.push({
              time: value.time,
              enter_unique_first_users: value.enter_unique_first_users,
              leave_unique_users: value.leave_unique_users,
              kick_unique_users: value.kick_unique_users,
            })
          }
        }
        return acc
      }, [])

    const { calcSeries } = seriesByMetricInList(this.$i18n, accStats, [ 'enter_unique_first_users', 'leave_unique_users', 'kick_unique_users' ], this.ticks)

    calcSeries()
      .then((res) => {
        this.series = res

        this.options = {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              allowMouseWheelZoom: false
            },
            animations: {
              enabled: false
            },
            defaultLocale: this.$i18n.locale,
            locales: ApexChartLocales
          },
          dataLabels: {
            enabled: false
          },
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: {
                total: {
                  enabled: false,
                }
              }
            },
          },
          xaxis: {
            type: 'datetime',
            categories: this.ticks.timeTicks,
          },
          yaxis: {
            labels: {
              formatter(val: number, opts?: any): string | string[] {
                return val.toFixed()
              }
            }
          },
        }

        this.chartLoading = false
      })
  }

  mounted() {
    this.calcChartSeries()
  }
}
